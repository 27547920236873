<template>
  <el-header height="auto" class="header">
    <div id="header">
      <div v-if="isMobile === false">
        <div class="gery" v-if="showTop">
          <div class="centerWidth">
            <div class="welcome" v-if="!userInfo || !userInfo.userId">
              {{ $t("header.webName") }}
              <span
                class="login"
                @click="login"
                style="color: red; cursor: pointer"
                >{{ $t("header.login") }}</span
              >
            </div>
            <div class="welcome" v-else>
              {{ $t('header.welcome', { username: `[${userInfo.username}]` }) }}
            </div>
            <div class="right">
              <!-- <template v-if="userInfo.userId">
                <div class="message">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-badge
                        :value="count"
                        class="item"
                        :max="99"
                        :hidden="!this.count"
                      >
                        <i class="el-icon-message-solid"></i>
                      </el-badge>
                    </span>
                    <el-dropdown-menu
                      v-if="languageSelect === 'zh'"
                      slot="dropdown"
                    >
                      <el-dropdown-item>
                        <router-link to="/message">
                          系统消息
                          <el-badge
                            class="mark"
                            v-if="count"
                            :value="count"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/subscriptionTheme"
                          >订阅主题
                          <el-badge
                            class="mark"
                            :value="ThremeCount"
                            v-if="ThremeCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/receivedlikes"
                          >收到的赞
                          <el-badge
                            class="mark"
                            v-if="LikeCount"
                            :value="LikeCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/replyToMy"
                          >回复我的
                          <el-badge
                            class="mark"
                            v-if="MyCount"
                            :value="MyCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                    <el-dropdown-menu
                      v-if="languageSelect === 'en'"
                      slot="dropdown"
                    >
                      <el-dropdown-item>
                        <router-link to="/message">
                          System Information
                          <el-badge
                            class="mark"
                            v-if="count"
                            :value="count"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/subscriptionTheme"
                          >Subscribe to Topics
                          <el-badge
                            class="mark"
                            :value="ThremeCount"
                            v-if="ThremeCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/receivedlikes"
                          >Likes
                          <el-badge
                            class="mark"
                            v-if="LikeCount"
                            :value="LikeCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <router-link to="/replyToMy"
                          >Response
                          <el-badge
                            class="mark"
                            v-if="MyCount"
                            :value="MyCount"
                            :max="99"
                          />
                        </router-link>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <el-dropdown class="userInfo">
                  <span class="el-dropdown-link">
                    <el-avatar
                      :size="32"
                      :src="userInfo.avatar ? baseURL + userInfo.avatar : avator"
                    ></el-avatar>
                    {{ userInfo.username }}
                  </span>
                  <el-dropdown-menu
                    v-if="languageSelect === 'zh'"
                    slot="dropdown"
                  >
                    <el-dropdown-item
                      v-if="userInfo.isVip == 1"
                      class="userIN flex"
                    >
                      <img src="@/assets/huiyuan.png" class="icon" alt="" />
                      {{ userInfo.memberName }}
                    </el-dropdown-item>
                    <el-dropdown-item class="hoverItem">
                      <div class="flex">
                        <img
                          src="@/assets/shuliang.png"
                          class="icon not"
                          alt=""
                        />
                        <img
                          src="@/assets/shuliangHover.png"
                          class="icon hover"
                          alt=""
                        />
                        咨询次数:
                        {{
                          (userInfo.freeNum ? userInfo.freeNum : 0) +
                          (userInfo.payNum ? userInfo.payNum : 0)
                        }}
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="hoverItem">
                      <router-link to="/Info" class="flex">
                        <img
                          src="@/assets/userIcon.png"
                          class="icon not"
                          alt=""
                        />
                        <img
                          src="@/assets/userHoverIcon.png"
                          class="icon hover"
                          alt=""
                        />
                        个人中心
                      </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item
                      @click.native="outLogin()"
                      class="hoverItem flex"
                    >
                      <img src="@/assets/outIcon.png" class="icon not" alt="" />
                      <img
                        src="@/assets/outHoverIcon.png"
                        class="icon hover"
                        alt=""
                      />
                      退出登录
                    </el-dropdown-item>
                  </el-dropdown-menu>
                  <el-dropdown-menu
                    v-if="languageSelect === 'en'"
                    slot="dropdown"
                  >
                    <el-dropdown-item class="hoverItem">
                      <router-link to="/Info" class="flex">
                        <img
                          src="@/assets/userIcon.png"
                          class="icon not"
                          alt=""
                        />
                        <img
                          src="@/assets/userHoverIcon.png"
                          class="icon hover"
                          alt=""
                        />
                        Personal Center
                      </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item
                      @click.native="outLogin()"
                      class="hoverItem flex"
                    >
                      <img src="@/assets/outIcon.png" class="icon not" alt="" />
                      <img
                        src="@/assets/outHoverIcon.png"
                        class="icon hover"
                        alt=""
                      />
                      Log out
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template> -->
              <template>
                <div>
                  <ul class="headerBox">
                    <li class="headerRed">
                      <a :href="($i18n.locale == 'en' ? 'https://oilgasdao.com/' : 'https://cn.oilgasdao.com/')" target="_blank">
                        <img
                          src="../assets/home.png"
                          alt=""
                          class="homeOil"
                        />{{ $t('header.oilDao') }}
                      </a>
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li class="headerRed">
                      <a :href="($i18n.locale == 'en' ? 'https://oilgasmall.com/' : 'https://cn.oilgasmall.com/')" target="_blank">{{ $t('header.oilExpo') }}</a>
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li class="headerRed">
                      <a href="https://www.oilgascommunity.com/" target="_blank"
                        >{{ $t('header.oilCommunity') }}</a
                      >
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li class="headerRed">
                      <a href="javascript:void(0);" >{{ $t('header.oilTalent') }}</a>
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li class="mobile">
                      <!-- 移动端 -->
                      <el-popover
                        placement="bottom"
                        title=""
                        width="110"
                        trigger="hover"
                        content=""
                        class="mobilePhoto"
                        style="min-width: 80px !important"
                      >
                        <a href="javascript:void(0);" slot="reference" class="mobile">{{ $t('header.mobileTerminal') }}</a>
                        <img
                          :src="require(`../assets/${trans('mobileCodeZh,mobileCodeEn')}.png`)"
                          alt=""
                          class="mobilePhoto"
                        />
                      </el-popover>
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li>
                      <a href="/Feedback" target="_blank">{{ $t('header.feedback') }}</a>
                    </li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
                    <li>
                      <a href="https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=2957254971134827&webPageTitle=%E5%9C%A8%E7%BA%BF%E5%92%A8%E8%AF%A2" target="_blank" rel="noopener noreferrer">{{ $t('header.service') }}</a></li>
                    <el-divider
                      direction="vertical"
                      style="margin-top: 20px"
                    ></el-divider>
<!--                    <li>-->
<!--                      <a :href="($i18n.locale == 'zh' ? 'https://www.oilgasinfoai.com/' : 'https://cn.oilgasinfoai.com/')" target="_blank">{{-->
<!--                        $i18n.locale == "zh" ? "English" : "中文"-->
<!--                      }}</a>-->
<!--                    </li>-->
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="nav-box">
          <div class="centerWidth flex">
            <router-link to="/" class="logo">
              <img src="@/assets/logo.png" alt="" />
            </router-link>
            <div class="centerWidth nav">
              <router-link
                v-for="(item, index) in navList"
                :key="index"
                :to="item.url || ''"
                :class="[
                  item.url == '/'
                    ? $route.path == item.url
                      ? 'active'
                      : ''
                    : $route.path.includes(item.url)
                    ? 'active'
                    : '',
                  'item',
                  item.url == '/Science?id=3' ? 'after' : '',
                  item.url == '/mySubscriptions' ? 'after' : '',
                ]"
              >
                {{ item[trans("name,nameEn")] }}
              </router-link>
            </div>
            <div class="rightSearch">
              <div v-if="false" class="searchBox">
                <el-button type="text" @click="dialogVisible = true"
                  ><i class="search-btn el-icon-search"></i
                ></el-button>
              </div>
              <el-dialog
                  :visible.sync="dialogVisible"
                  fullscreen
                  :show-close="false"
                  :modal-append-to-body="false"
                  :brefore-close="handleClose"
                  :destroy-on-close="true"
                >
                  <div class="search-popup-box centerWidth">
                    <router-link to="/" class="logo">
                      <img src="@/assets/logo.png" alt="" class="logo" />
                    </router-link>
                    <i @click="handleClose" class="el-icon-close"></i>
                  </div>
                  <div  class="searchCenter">
                    <div class="searchBox">
                      <el-input
                        class="searchContent"
                        v-model="seachInput"
                        :placeholder="keywordDefault"
                      ></el-input>
                      <i class="el-icon-search"
                        @click="searchHandle"
                      ></i>
                    </div>
                    <div class="searchTerms">
                      <span># {{ $t('search.hotSearch') }} #</span>
                    </div>
                    <div class="searchWord">
                      <el-button size="primary" v-for="(item, index) in hotSearch" :key="index" plain @click="hotSearchHandle(index)">{{item}}</el-button>
                    </div>
                  </div>
                </el-dialog>
            </div>
            <div class="avatar" @click="!userInfo.userId && login()">
              <el-dropdown
                size="small"
                @command="handleCommand"
                :disabled="!userInfo.userId"
                >
<!--                <el-avatar-->
<!--                  :class="userInfo.avatar ? '' : 'custom-avatar'"-->
<!--                  :src="userInfo.userId ? userInfo.avatar ? baseURL + userInfo.avatar : require('@/assets/avator.png') : ''"-->
<!--                  :size="'1vw'"-->
<!--                ></el-avatar>-->
                <div :class="userInfo.avatar ? '' : 'custom-avatar'" style="width: 2vw;height: 2vw">
<!--                  <img :src="userInfo.userId ? userInfo.avatar ? baseURL + userInfo.avatar : require('@/assets/avator.png') : ''" style="height: 100%;width: 100%;">-->
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="item.k"
                    v-for="(item, index) in menus"
                    :class="{ 'custom-username oneLine': index == 0 }"
                    :divided="index != 0"
                    :key="item.k"
                    >{{ item.v }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="centerWidth1 flex1">
          <router-link to="/" class="logo1">
            <img src="@/assets/logo.png" alt="" />
          </router-link>
          <div class="m-header-right">
            <i class="el-icon-search" @click="searchHandle"></i>
            <i class="m-fold-icon" @click="dialogTableVisible = true"></i>
          </div>
        </div>
        <el-dialog :visible.sync="dialogTableVisible" fullscreen :show-close="false" custom-class="m-nav-box">
          <div class="drop">
            <router-link to="/" class="logo2">
              <img src="@/assets/logo.png" class="dropImage" alt="" />
            </router-link>
            <div class="m-header-right">
              <i class="el-icon-search" @click="searchHandle"></i>
              <i class="el-icon-close" @click="dialogTableVisible = false"></i>
            </div>
          </div>
          <div class="m-login-info">
            <el-avatar
              @click.native="!userInfo.userId ? login() : toPersonPage()"
              :class="userInfo.avatar ? '' : 'custom-avatar'"
              :src="userInfo.avatar ? baseURL + userInfo.avatar : ''"
              :size="24"
            ></el-avatar>
            <span v-if="!userInfo.userId" @click="!userInfo.userId && login()">{{ $t('common.loginRegi') }}</span>
            <span v-if="!userInfo.userId">{{ $t('index.mLoginTip') }}</span>
            <template v-else >
              <span @click="toPersonPage()">{{userInfo.username}}</span>
              <span>{{ $t('header.welcome', { username: '' }) }}</span>
            </template>
          </div>
          <div class="dropLine" v-for="item in mNavs" :key="item.id">
            <p class="dropLT1" @click="toList(item)">{{item[trans('name,nameEn')]}}</p>
            <div class="dropLT2">
              <p v-for="sub in item.child" @click="toList(item, sub)" :key="sub.categoryId || sub.id">{{sub[trans('title,titleEn')]}}</p>
            </div>
          </div>
          <div class="dropFooter">
            <a href="https://cn.oilgasinfoai.com/" style="color: blue !important;" >中文</a>
            <span>|</span>
            <a href="https://www.oilgasinfoai.com/" style="color: blue !important;">English</a>
          </div>
        </el-dialog>
      </div>
      <PClogin :dialogVisible="cancleVisible" @hide="hide"></PClogin>
    </div>
  </el-header>
</template>
<script>
import {
  logOut,
  userDetail,
  getAuthorize,
  // getRegister,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";
import { navList, getNewsCates, getCates } from "@/api/index";
import PClogin from "@/components/PClogin.vue";
import mixins from "@/utils/mixins.js";
import { mapState } from "vuex";

export default {
  props: {
    showTop: {
      type: Boolean,
      default: true
    }
  },
  components: { PClogin },
  mixins: [mixins],
  data() {
    return {
      options: [
        { value: "1", label: "新建对话", iconClass: "el-icon-chat-dot-round" },
        {
          value: "2",
          label: "历史记录",
          iconClass: "el-icon-document-checked",
        },
        // Add more options as needed
      ],
      cancleVisible: false,
      selectedOption: null,
      activeIndex: null,
      isMobile: false,
      languageSelect: "",
      // userInfo: this.$store.state.user,
      userId: "",
      avator: require("@/assets/avator.png"),
      seachInput: "",
      activeName: "",
      navList: this.$store.state.navList,
      count: this.$store.state.MessageCount,
      ThremeCount: 0,
      LikeCount: 0,
      MyCount: 0,
      allCount: this.count,
      primaryTypeid: null,
      navLinks: {
        zh: {
          text: "关于我们", // 中文导航文本
          link: "/language=zh", // 中文导航链接
        },
        en: {
          text: "About Us", // 英文导航文本
          link: "/language=en", // 英文导航链接
        },
      },
      murmur: null,

      dialogTableVisible: false,
      dialogVisible: false,
      hotSearch: [this.$t('header.searchHotword1'), this.$t('header.searchHotword2'), this.$t('header.searchHotword3'), this.$t('header.searchHotword4'), this.$t('header.searchHotword5')],
      keywordDefault: this.$t('header.searchKeyword'),
      mNavs: []
    };
  },
  created(){
    this.isMobile = this.$route.meta.isMobile
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      return;
    }
    next();
  },
  computed: {
    menus() {
      let menus = [
        {
          k: "username",
          v: this.userInfo.username,
          command: false,
        },
        {
          k: "info",
          v: this.$t('user.info'),
          command: "/Info",
        },
        {
          k: "collect",
          v: this.$t('user.myCollection'),
          command: "/collection",
        },
        {
          k: "order",
          v: this.$t('user.myOrders'),
          command: "/orderList",
        },
        {
          k: "notice",
          v: this.$t('user.message'),
          command: "/message",
        },
        {
          k: "logout",
          v: this.$t('user.logout'),
          command: false,
        },
      ];
      return menus;
    },
    ...mapState({
      access_token: "access_token",
      userInfo: "user",
    }),
  },
  watch: {
    // "$store.state.user"(newValue) {
    //   this.userInfo = newValue;
    // },
    "$store.state.MessageCount"(newValue) {
      this.count = newValue;
    },
    // "$store.state.userId"(newValue, oilValue) {
    //   this.userId = newValue;
    //   if (newValue && newValue != oilValue) {
    //     this.getUser();
    //   }
    // },
  },
  async mounted() {
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });

    await this.getNavList();
    this.getMNavs();

    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.activeName = this.$route.path;

    if (this.$route.query.Typeid) this.primaryTypeid = this.$route.query.Typeid;
    if (this.$route.query.id) this.primaryTypeid = this.$route.query.id;

    // if (localStorage.getItem("Title")) {
    //   document.title = localStorage.getItem("Title");
    // }
    // this.isMobile = window.matchMedia("(max-width: 767px)").matches;

    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      // this.getUser();
    }
  },
  methods: {
    handleCommand(command) {
      const menu = this.menus.find((item) => item.k == command);
      if (menu) {
        if (menu.command) {
          this.$router.push(menu.command);
          return;
        }
        if (menu.k == "logout") {
          this.outLogin();
        }
      }
    },
    hotSearchHandle(index){
      this.seachInput = this.hotSearch[index]
      this.searchHandle()
    },
    // 搜索
    searchHandle() {
      this.dialogVisible = false;
      this.dialogTableVisible = false;
      sessionStorage.setItem("keywords", this.seachInput);
      // this.$emit("keywords", { seachInput: this.seachInput, searchChange: 1 });

      // 设置搜索关键词的长度
      let keywords = this.seachInput.substr(0, 20) || (this.isMobile ? '' : this.keywordDefault);
      this.$router.push(`/search/${ encodeURIComponent(keywords) }`);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      // https://www.oilgasinfoai.com
      this.$axios
        .post("/auth/oauth/getLoginStatus", data)
        .then((res) => {
          console.log(res, "res11111", this.$store.state.access_token);
          let options = this.$route.query;
          if (res.data.data.isLogin == true && this.$store.state.access_token) {
            this.getUser();
          } else if (
            res.data.data.isLogin == true &&
            !this.$store.state.access_token &&
            !options.code
          ) {
            this.login();
          } else if (
            res.data.data.isLogin == false &&
            res.data.data.type == 2
          ) {
            console.log("isLogin Flase type 2");
            //this.outLogin();
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
          } else if (
            res.data.data.isLogin == false &&
            res.data.data.type == 1
          ) {
            console.log("isLogin Flase type 1");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // this.login();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUser() {
      userDetail().then((res) => {
        // this.userInfo = res.data;
        // console.log(this.userInfo)
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$store.state.user = res.data;
        this.userId = res.data.userId;
        // console.log(this.userInfo)
        this.noticeCount();
      });
    },
    noticeCount() {
      this.noticeCountCom();
      setInterval(() => {
        this.noticeCountCom();
      }, 1000 * 60);
    },
    login() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    // 退出登录
    outLogin() {
      logOut()
        .then((res) => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userId");
          localStorage.removeItem("typeInfo");
          localStorage.removeItem("access_tokenout");
          localStorage.removeItem("Zyaccess_token");
          localStorage.removeItem("idass");
          this.$store.state.access_token = "";
          this.$store.state.idass = "";
          this.$store.state.user = {};
          // deleteUserAgent(localStorage.getItem("murmur"))
          let data = {
            userAgent: localStorage.getItem("murmur"),
          };
          this.$axios
            .post(
              "/auth/oauth/deleteUserAgent",
              data
            )
            .then(() => {
              localStorage.removeItem("murmur");
              window.location.href = res.data;
            });
        })
        .catch((error) => {
          console.error("退出登录失败:", error);
          this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
          // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
        });
    },
    async getNewsCatesHandle(level, parentId){
      let res = await getNewsCates({
        level, parentId
      })
      return res.data
    },
    async getCatesHandle(level, parentId){
      let res = await getCates({
        level, parentId
      })
      return res.data
    },
    async getNavList() {
      let res = await navList()
      this.navList = res.data;
      this.$store.state.navList = res.data;
    },
    getMNavs(){
      const map = {
        4: 1,  // 科技
        3: 4,  // 新闻
        5: '',   // 报告
        8: 3,   // 商机
      }
      let temp = []
      this.navList.map(async (item) => {
        if(!('2,7'.includes(item.bannerId))){
          let fn = map[item.bannerId] ? this.getNewsCatesHandle : this.getCatesHandle;
          temp.push(item)
          let res = await fn(2, map[item.bannerId])
          item.child = res
        }
      })
      this.mNavs = temp;
    },
    toList(parent, sub){
      this.dialogTableVisible = false
      const map = {
        4: 'Technology',
        8: 'Opportunity',
        3: 'News',
        5: 'Report'
      }
      let params = (!sub ? {} : (parent.bannerId == 5 ? { parentId: sub.parentId, cateId: sub.id } : { cateId: sub.categoryId }))

      if(this.$route.name == map[parent.bannerId]){
        this.$emit('handle-nav', params)
      }else{
        this.$router.push({
          name: map[parent.bannerId],
          params
        })
      }
    },
    toPersonPage(){
      this.dialogTableVisible = false
      this.$router.push({
        name: 'User'
      })
    },


    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    bin2hex(str) {
      var result = "";
      for (let i = 0; i < str.length; i++) {
        result += this.int16ToHex(str.charCodeAt(i));
      }
      return result;
    },
    int16ToHex(i) {
      var result = i.toString(16);
      var j = 0;
      while (j + result.length < 4) {
        result = "0" + result;
        j++;
      }
      return result;
    },

    PClogin() {
      this.$router.push("/Feedback");
      // this.cancleVisible = true;
    },
    hide() {
      this.cancleVisible = false;
    },
    showPopover() {
      this.$refs.customPopover.doToggle(); // 打开弹出框
    },
    selectOption(item) {
      this.selectedOption = item;
      this.$refs.customPopover.doClose(); // 选中选项后关闭弹出框
      // 根据需要执行其他操作
    },
    setActive(index) {
      this.activeIndex = index;
      // 确保导航数据已加载
      this.$nextTick(() => {
        // 获取导航项的父容器
        const navWrapper = document.querySelector(".nav1");
        if (navWrapper) {
          // 获取被点击项的位置（假设每个导航项宽度相同）
          const itemWidth = navWrapper.children[0].offsetWidth;

          // 将被点击项移动到中心位置
          navWrapper.scrollTo({
            left: itemWidth * index - (navWrapper.offsetWidth - itemWidth) / 2,
            behavior: "smooth",
          });
        }
      });
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.$store.dispatch("setLanguage", language);

      // localStorage.setItem("language", language);
      this.languageSelect = localStorage.getItem("language");
      // 获取当前路由信息
      const currentRoute = this.$router.currentRoute;
      // 获取当前路由的查询参数
      const query = { ...currentRoute.query };
      // 更新语言参数为新的语言
      query.language = language;
      // 使用 $router.replace 更新路由，并传入新的查询参数
      this.$router.replace({ query }).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
      window.location.reload();
    },
    // 注册
    // Register() {
    //   // let href = "https://www.oilgasinfoai.com/?language=zh";

    //   let href = window.location.href.split("&code")[0];
    //   getRegister(href).then((res) => {
    //     console.log(res);
    //     window.location.href = res.data;
    //   });
    // },
    // 登录

  },
};
</script>

<style>
.el-popover {
  min-width: 0 !important;
  padding: 0 !important;
}

.bannerBox {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.bannerBox > li {
  width: 155px;
  height: 155px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
  /* background-image: url(https://imgs.ebrun.com/master/2021/images/error_1_1.jpg); */
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 10px 10px 0;
  cursor: pointer;
  position: relative;
}

.bannerPhoto {
  position: relative;
  overflow: hidden;
}
.bannerT {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 10px;
  height: 52px;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 16px;
  font-size: 14px;
  color: #fff;
  transition: all 0.5s;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.66) 57%, #000);
}
</style>
<style lang="scss" scoped>
#header {
  background: #fff;
  .gery {
    background: #f8f8f8;
    border-bottom: 1px solid #eeeeee;
    height: 40px;

    .centerWidth {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      .welcome {
        font-size: 14px;
        color: #666666;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > div {
          // margin-left: 10px;
          span {
            cursor: pointer;
          }
        }

        .message {
          .el-dropdown {
            .el-icon-message-solid {
              font-size: 20px;
            }

            .el-badge__content {
              border: none;
            }

            .el-dropdown-menu__item {
              margin-bottom: 10px;
            }

            .el-dropdown-link {
              cursor: pointer;
            }

            .el-icon-arrow-down {
              font-size: 14px;
              color: #666666;
              line-height: 40px;
            }

            .demonstration {
              display: block;
              color: #8492a6;
              font-size: 14px;
              margin-bottom: 20px;
            }
          }
        }

        .userInfo {
          .el-dropdown-link {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .el-avatar {
              margin-right: 10px;
            }
          }
        }

        .login,
        .register,
        .forgetPassword {
          color: #666666;
          font-size: 14px;
          margin: 0 18px;
          position: relative;
        }

        .login:hover,
        .register:hover,
        .forgetPassword:hover {
          color: var(--main-color);
        }

        .login::after {
          content: " ";
          background: #666666;
          width: 1px;
          height: 12px;
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        .chooseLanguage {
          color: #666666;
          border-style: none;
          background-color: inherit;
          height: 30px;
          // line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
          padding: 10px 0 !important;
        }

        .chooseLanguage:focus,
        .chooseLanguage:hover {
          color: #666666;
          border-style: none;
          background-color: inherit;
        }
      }
    }
  }
  .nav-box {
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
    z-index: 20;
  }
  .centerWidth.flex {
    display: flex;

    justify-content: space-between;
    align-items: center;
    padding: 1vw 0;

    .logo {
      width: 8vw;
      height: auto;
      margin-right: 40px;
    }

    .rightSearch {
      flex: 1;
      margin: 0 50px;
      // margin-right: 30px;

      .searchBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // border: 1px solid #666666;
        // background-color: rgba(201, 201, 201, 0.1);
        // border-radius: 35px;
        // height: 60px;



        .searchTo {
          width: 96px;
          height: 15px;
          font-size: 14px;
          border: none;
          background: transparent;
          border-radius: 0;
          color: #999999;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-left: #666666 1px solid;
        }
      }
    }
    .searchCenter{
      .searchBox {
        border-bottom: 1px solid #000;
      }
      .searchContent {
          width: 100%;
          padding-right: 50px;
          // padding-left: 15px;
          font-size: 30px;
          line-height: 60px;
          border-radius: 0;
          color: #999999;
          height: 60px;
          // background-color: rgb(250, 250, 255);
          opacity: 1;
          // margin-right: 100px;


          ::v-deep .el-input__inner {
            height: 100%;
            padding: 20px 0;
            background-color: transparent;
            // border-radius: 30px;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
            padding: 20px 0;
            // background-color: rgba(201, 201, 201, 0.1);
            // border-radius: 30px;
          }


      }
      .el-icon-search{
        font-size: 34px;
        cursor: pointer;
      }
    }
  }

  .centerWidth1.flex1 {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 12px 0 #e6ebf2;
    // padding-top: 10px;
    // padding-bottom: 10px;

    .logo1 {
      width: 25%;
      // margin-top: -5px;
      margin-left: 2%;
      height: auto;
    }

    .el-button {
      padding: 1px 0;
    }

    .rightSearch1 {
      // flex: 1;
      margin-left: 45%;

      .searchBox1 {
        margin-top: -5px;
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #999999;
        background-color: rgb(250, 250, 255);

        .searchContent1 {
          float: left;
          width: 100%;
          border-radius: 15px;
          color: #999999;
          background-color: rgb(100, 98, 98, 0.1);
          height: 25px;

          ::v-deep .el-input__inner {
            height: 100%;
            border-radius: 15px;
            background-color: rgb(100, 98, 98, 0.1);
            padding: 0 5px;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
            font-size: 8px;
          }
        }
        .searchTo1 {
          width: 25%;
          height: 25px;
          border: none;
          background: var(--main-color);
          border-radius: 0;

          img {
            width: 16px;
            height: 16px;
            align-items: center;
          }
        }
      }
    }
  }

  .nav {
    width: 60%;
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 48px;

    > .item {
      flex: 1;
      text-align: center;
      font-size: 18px;
      font-weight: bolder;
      &:hover{
        color: var(--main-color);
      }
    }

    .active {
      color: #1a79ff;
      position: relative;
    }

    .active::after {
      content: "";
      margin-left: 20%;
      width: 60%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #1a79ff;
    }

    .after {
      position: relative;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: #999999;
    }
  }

  .nav1 {
    display: flex;
    align-items: center;
    height: 28px;
    line-height: 15px;
    font-size: 14px;
    overflow-x: auto; /* 开启水平滚动 */
    white-space: nowrap; /* 防止导航项换行 */
    &::-webkit-scrollbar {
      display: none; /* 显示滚动条 */
    }

    > .item {
      flex: 1;
      margin: 0 5px; /* 添加间距 */
      text-align: center;
    }

    .active {
      color: var(--main-color);
      position: relative;
    }

    .active::after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: -5px;

      margin-top: 5px;
      left: 0;
      background: var(--main-color);
    }

    .after {
      position: relative;
      margin-right: 5px;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 10px;
      position: absolute;
      left: -5px;
      top: 1px;
      background: #999999;
    }
  }

  .search-btn {
    font-size: 1.2vw;
    color: #a1a8b3;
    margin-left: 15px;
    &:hover {
      color: var(--main-color);
    }
  }
  .custom-avatar {
    background: url("../assets/icon_user_default.png") no-repeat 0 0;
    background-size: 100%;
    &:hover {
      background-image: url("../assets/icon_user_highlight.png");
    }
  }
  .el-avatar {
    cursor: pointer;
    background-color: transparent;
  }
}
.custom-username {
  width: 4em;
  text-align: center;
  font-weight: bold;
}

::v-deep.el-dropdown-menu.el-dropdown-menu--small {
  padding: 6px 16px;
  .el-dropdown-menu__item {
    padding: 0 18px;
  }
}

.flex {
  display: flex;
  align-items: center;

  img.icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .icon.hover {
    display: none;
  }
}

.hoverItem:hover {
  .icon.hover {
    display: inline-block;
  }

  .icon.not {
    display: none;
  }
}

::v-deep.el-header {
  padding: 0;
}
::v-deep .el-popover .el-popper {
  // min-width: 100px !important;
}
::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  color: var(--main-color);
}
::v-deep.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 2px 0;
}
.userIN:not(.is-disabled):hover {
  background: none;
  color: #666;
}
.userIN {
  color: var(--main-color) !important;
}

.el-link.el-link--default:hover {
  color: var(--main-color);
}

.homeOil {
  width: 20px;
  height: 15px;
  object-fit: contain;
  // margin-right: 5px;
  vertical-align: middle;
  margin-top: -4px;
}
.headerBox {
  list-style: none;
  display: flex;
}

.headerBox > li {
  // padding-right: 20px;
  font-size: 13px;
  line-height: 40px;
}

.headerBox > li:hover {
  color: #bf0022;
}
.el-divider--vertical {
  margin: 13px 10px !important;
}

.headerRed {
  color: #bf0022;
}

.mobile {
  background: #f8f8f8;
  border: 0 !important;
}

.mobile:hover,
.mobile:focus {
  border: 0 !important;
  color: #bf0022;
  background: #f8f8f8;
  // background: white !important;
}

.mobilePhoto {
  width: 110px !important;
  height: 110px !important;
}

::v-deep #el-popover-285 {
  min-width: 80px !important;
}

::v-deep .el-dialog {
  margin-top: 0vh !important;
  height: 100%;
  background: rgb(255, 255, 255);
  opacity: 0.9;
}

::v-deep .el-popover {
  min-width: 80px !important;
}
// ::v-deep .el-dialog__header {
//   padding: 0;
// }

::v-deep .el-dialog__headerbtn {
  top: 4% !important;
  cursor: pointer;
  z-index: 999;
}

.searchCenter {
  width: 730px;
  margin: 100px auto 0;
}

.searchBox1 {
  width: 730px;
  height: 36px;
  // line-height: 36px;
  // text-align: center;
  display: flex;
  // justify-content: center;
  // margin-left: 350px;
  // margin-top: 50px;
  border-bottom: 1px solid black;
  background-color: rgb(250, 250, 255);
}

.searchTerms {
  display: flex;
  margin: 20px 0;
}
.searchWord {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
}

::v-deep .el-button--primary.is-plain {
  border: 0;
  color: #a1a8b3;
  font-size: 16px;
  background: rgb(255, 255, 255);
  min-width: 64px;
  height: 30px;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 30px;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover{
    background-color: var(--main-color);
    color: #fff;
    border-color: var(--main-color);
  }
}

.drop {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
}

.dropImage {
  // width: 125px;
  // height: 20px;
}

::v-deep .el-dialog {
  width: 100% !important;
}

.logo2 {
  width: 25%;
  // margin-top: -5px;
  margin-left: 2%;
  height: auto;
  img {
    width: 100%; /* 图像宽度填满容器 */
    height: auto; /* 让高度自适应，保持宽高比 */
  }
}

.rightSearch2 {
  // flex: 1;
  margin-left: 45%;

  .searchBox1 {
    margin-top: -5px;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #999999;
    background-color: rgb(250, 250, 255);

    .searchContent1 {
      float: left;
      width: 100%;
      border-radius: 15px;
      color: #999999;
      background-color: rgb(100, 98, 98, 0.1);
      height: 25px;

      ::v-deep .el-input__inner {
        height: 100%;
        border-radius: 15px;
        background-color: rgb(100, 98, 98, 0.1);
        padding: 0 5px;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #999;
        font-size: 8px;
      }
    }
    .searchTo1 {
      width: 25%;
      height: 25px;
      border: none;
      background: var(--main-color);
      border-radius: 0;

      img {
        width: 16px;
        height: 16px;
        align-items: center;
      }
    }
  }
}

.dropUser {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #e6ebf2;
  border-radius: 50%;
}

.dropTitle1 {
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-left: 10px;
}

.dropTitle2 {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
  line-height: 25px;
}

.dropLine {
  display: flex;
  margin: 0 14px 10px;
  .dropLT1 {
    font-size: 16px;
    color: #17181a;
    margin-right: 30px;
    min-width: 5em;
  }
  .dropLT2 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    color: #666;
    p {
      min-width: 4em;
      font-size: 14px;
      margin-bottom: 12px;
      margin-right: 20px;
    }
  }
}

.dropFooter {
  position: absolute;
  left: 14px;
  bottom: 50px;
  width: calc(100% - 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;
  padding-top: 8px;
  span, a{
    margin-right: 15px ;
    &:last-child{
      margin-right: 0;
    }
  }
}

.search-popup-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-icon-close{
    font-size: 40px;
    cursor: pointer;
  }
}


::v-deep .m-nav-box.el-dialog{
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.m-nav-box{
  .drop{
    position: static;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-bottom: 10px;
  }
}
.m-header-right{
  display: flex;
  margin-right: 2%;
  .el-icon-search{
    font-size: 22px;
    margin-right: 18px;
    cursor: pointer;
  }
  .m-fold-icon{
    display: block;
    width: 22px;
    height: 22px;
    background: url('../assets/fold.png') no-repeat 0 0;
    background-size: cover;
    cursor: pointer;
  }
  .el-icon-close{
    font-size: 22px;
  }
}
.m-login-info{
  display: flex;
  margin: 0 14px 20px;
  padding-bottom: 8px;
  align-items: center;
  border-bottom: 1px solid #eee;
  & > span:nth-child(2){
    color: #333;
    font-weight: bold;
    margin-left: 10px;
  }
  & > span:nth-child(3){
    color: #999;
    font-size: 12px;
    margin-left: 10px;
  }
}


</style>
